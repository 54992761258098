import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import { url } from '../env';

function Chatbot() {
    const chatRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [loading, setLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [dragDisabled, setDragDisabled] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false); // Track chat window open state

    function getUserId() {
        let userId = localStorage.getItem('user_id');
        if (!userId) {
            userId = Math.random().toString(36).substr(2, 9);
            localStorage.setItem('user_id', userId);
        }
        return userId;
    }

    useEffect(() => {
        const element = $(chatRef.current);
        const myStorage = localStorage;

        if (!myStorage.getItem('chatID')) {
            myStorage.setItem('chatID', createUUID());
        }

        setTimeout(() => {
            element.addClass('enter');
        }, 1000);
        element.on('click', openElement);

        function openElement() {
            setIsChatOpen(true); 
            const textInput = element.find('.text-box');
            element.find('>i').hide();
            element.addClass('expand');
            element.find('.chat').addClass('enter');
            textInput.keydown(onEnterPress).prop('disabled', false).focus();
            element.off('click', openElement);
            element.find('.header button').on('click', closeElement);
            element.find('#sendMessage').on('click', sendNewMessage);
            element.find('.messages').scrollTop(element.find('.messages').prop('scrollHeight'));
        }
        function closeElement() {
            element.find('.chat').removeClass('enter').hide();
            element.find('i.fa-comments').show();
            element.removeClass('expand');
            element.find('.header button').off('click', closeElement);
            element.find('#sendMessage').off('click', sendNewMessage);
            element.find('.text-box').off('keydown', onEnterPress).prop('disabled', true).blur();
            setTimeout(() => {
                element.find('.chat').removeClass('enter').show();
                element.on('click', openElement);
            }, 500);
        }
        // function openElement() {
        //     setIsChatOpen(true); 
        //     const textInput = element.find('.text-box');
        //     element.find('>i').hide();
        //     element.addClass('expand');
        //     element.find('.chat').addClass('enter');
        //     textInput.keydown(onEnterPress).prop('disabled', false).focus();
        //     element.off('click', openElement);
        //     element.find('.header button').on('click', closeElement);
        //     element.find('#sendMessage').on('click', sendNewMessage);
        //     element.find('.messages').scrollTop(element.find('.messages').prop('scrollHeight'));
        // }

        // function closeElement() {
        //     setIsChatOpen(false); 
        //     element.find('.chat').removeClass('enter').hide();
        //     element.find('i.fa-comments').show();
        //     element.removeClass('expand');
        //     element.find('.header button').off('click', closeElement);
        //     element.find('#sendMessage').off('click', sendNewMessage);
        //     element.find('.text-box').off('keydown', onEnterPress).prop('disabled', true).blur();
        //     setTimeout(() => {
        //         element.find('.chat').removeClass('enter').show();
        //         element.on('click', openElement);
        //     }, 500);
        // }

        function createUUID() {
            let s = [];
            const hexDigits = '0123456789abcdef';
            for (let i = 0; i < 36; i++) {
                s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
            }
            s[14] = '4';
            s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
            s[8] = s[13] = s[18] = s[23] = '-';
            return s.join('');
        }

        function sendNewMessage() {
            const userInput = element.find('.text-box');
            const newMessage = userInput.val().trim();

            if (!newMessage) return;

            setMessages(prevMessages => [
                ...prevMessages,
                { type: 'self', text: newMessage }
            ]);

            userInput.val('');
            userInput.focus();
            const payload = {
                user_id: getUserId(),
                message: newMessage
            };
            setLoading(true);
            setTimeout(() => {
                fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(payload)
                })
                    .then(response => response.json())
                    .then(data => {
                        setLoading(false);
                        console.log(data);
                        const newMessages = [{
                            type: 'other',
                            text: data.response
                        }];
                        setMessages(prevMessages => [...prevMessages, ...newMessages]);
                    })
                    .catch(error => {
                        setLoading(false);
                        console.error('Error:', error);
                    });
            }, 150);
        }

        function onEnterPress(event) {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                sendNewMessage();
            }
        }

        const handleMouseDown = (e) => {
            if (isChatOpen && !dragDisabled) { // Check if chat is open
                setIsDragging(true);
                const rect = element[0].getBoundingClientRect();
                setOffset({
                    x: e.clientX - rect.left,
                    y: e.clientY - rect.top
                });
                element.addClass('move');
            }
        };

        const handleMouseMove = (e) => {
            if (isDragging && isChatOpen && !dragDisabled) { // Check if chat is open
                const windowWidth = $(window).width();
                const windowHeight = $(window).height();
                const elementWidth = element.outerWidth();
                const elementHeight = element.outerHeight();
                let newTop = e.clientY - offset.y;
                let newLeft = e.clientX - offset.x;

                // Ensure the chat doesn't move outside the window boundaries
                newTop = Math.max(0, Math.min(newTop, windowHeight - elementHeight));
                newLeft = Math.max(0, Math.min(newLeft, windowWidth - elementWidth));
                element.css({
                    top: `${newTop}px`,
                    left: `${newLeft}px`
                });
            }
        };

        const handleMouseUp = () => {
            setIsDragging(false);
            element.removeClass('move');
        };

        element.on('mousedown', handleMouseDown);
        $(window).on('mousemove', handleMouseMove);
        $(window).on('mouseup', handleMouseUp);

        const messagesArea = element.find('.messages');
        messagesArea.on('mouseenter', () => setDragDisabled(true));
        messagesArea.on('mouseleave', () => setDragDisabled(false));

        return () => {
            element.off('click', openElement);
            element.off('mousedown', handleMouseDown);
            $(window).off('mousemove', handleMouseMove);
            $(window).off('mouseup', handleMouseUp);
            messagesArea.off('mouseenter');
            messagesArea.off('mouseleave');
        };
    }, [isDragging, offset, loading, dragDisabled, isChatOpen]);

    useEffect(() => {
        if (messages.length > 0) {
            const messagesContainer = chatRef.current.querySelector('.messages');
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    }, [messages]);

    return (
        <div className={`floating-chat`} ref={chatRef} draggable="false">
            <i className="fa fa-comments" aria-hidden="true"></i>
            <div className="chat">
                <div className="header">
                    <span className="title">DevPandas Assistant</span>
                    <button onClick={() => $(chatRef.current).find('.header button').trigger('click')}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                    </button>
                </div>
                <ul className="messages">
                    {messages.map((message, index) => (
                        <li key={index} className={message.type}>
                            {message.text}
                        </li>
                    ))}
                    {loading && <li className="other"><div className="loading">Loading...</div></li>}
                </ul>
                <div className='btn_wrap'>
                    <div className="scroll-container"></div>
                </div>
                <div className="footer">
                    <input type="text" className="text-box" contentEditable="true" placeholder='Enter here....' />
                    <button id="sendMessage">
                        <i className="fa-regular fa-paper-plane"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Chatbot;