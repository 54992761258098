import './App.css';
import Chatbot from './component/Chatbot';

function App() {
  return (
    <>
      <Chatbot />
    </>
  );
}

export default App;
